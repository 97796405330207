import { css } from '@emotion/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { getLoginLink } from 'ms-auth';
import { Button } from 'shared/components/presentational';
import { MicrosoftLogo } from 'shared/components/svgs';
import { colors } from 'shared/settings';

const MicrosoftAuthButton = () => {
    const translate = useTranslations('login');
    const [inProgress, setInProgress] = useState(false);

    const handleClick = async () => {
        setInProgress(true);
        window.location.href = getLoginLink();
    };

    return (
        <Button
            onClick={handleClick}
            disabled={inProgress}
            startIcon={<MicrosoftLogo />}
            variant="outlined"
            css={css`
                width: 316px;
                height: 40px;
                background-color: ${colors.neutrals.white};
                border: 1px solid ${colors.grays.miscka};
                border-radius: 2px;
                color: ${colors.neutrals.black};
            `}
        >
            {translate('sign-in-with-microsoft')}
        </Button>
    );
};

export { MicrosoftAuthButton };
