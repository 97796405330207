import { css } from '@emotion/react';
import { Box } from '@mui/material';
import pick from 'lodash/pick';
import type { NextPage } from 'next';
import { useTranslations } from 'next-intl';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import { trackLoadPage } from 'analytics';
import { Hyperlink } from 'shared/components/presentational';
import { GoogleAuthButton } from 'shared/components/presentational/google-auth-button';
import { MicrosoftAuthButton } from 'shared/components/presentational/microsoft-auth-button';
import { ArrowLeft, HireflowLogo } from 'shared/components/svgs';
import {
    BOOK_A_DEMO_URL,
    GOOGLE_API_SERVICES_USER_DATA_POLICY_URL,
    HIREFLOW_HOME_URL,
    PRIVACY_POLICY_URL,
    TERMS_OF_SERVICE_URL,
} from 'shared/constants';
import { colors, fontFamilies, fontSizes, fontWeights, spacing } from 'shared/settings';
import { Locales } from 'shared/types';

const namespaces: IntlNamespaces[] = ['login'];

const DemoLink = (children: ReactNode) => (
    <Hyperlink href={BOOK_A_DEMO_URL} newTab>
        {children}
    </Hyperlink>
);

const DisclaimerLink = (url: string) => (children: ReactNode) =>
    (
        <a
            css={css`
                text-decoration: underline;
                color: ${colors.greens.eucalyptus};
            `}
            href={url}
            target="_blank"
            rel="noreferrer"
        >
            {children}
        </a>
    );

const Login: NextPage = () => {
    const translate = useTranslations('login');
    const router = useRouter();

    useEffect(() => {
        trackLoadPage('sign_in');
    }, []);

    return (
        <>
            <Head>
                <title>{translate('page-name')}</title>
                <meta name="description" content="Login to Hireflow" />
                <link rel="icon" href="/hireflow-logo.png" />
            </Head>

            <Box
                css={css`
                    padding: ${spacing.space16px};
                    display: flex;
                    height: 100vh;
                    background-color: ${colors.neutrals.white};
                    align-items: center;
                    justify-content: center;
                `}
            >
                <Box
                    css={css`
                        height: 100%;
                        max-height: 836px;
                        flex-basis: 37%;
                        max-width: 540px;
                        padding: ${spacing.space32px};

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    `}
                >
                    <Hyperlink
                        css={css`
                            max-width: 80px;
                        `}
                        href={HIREFLOW_HOME_URL}
                    >
                        <Box
                            css={css`
                                display: inline-flex;
                                align-items: center;
                                gap: ${spacing.space8px};

                                font-weight: ${fontWeights.semiBold};
                            `}
                        >
                            <ArrowLeft />
                            {translate('back')}
                        </Box>
                    </Hyperlink>
                    <Box
                        css={css`
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 30px;
                        `}
                    >
                        <Box>
                            <HireflowLogo />
                        </Box>
                        <Box
                            css={css`
                                font-size: ${fontSizes.f24};
                                font-weight: ${fontWeights.bold};
                                font-family: ${fontFamilies.poppins};
                            `}
                        >
                            {translate('log-in-to-hireflow')}
                        </Box>
                        <Box
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: ${spacing.space12px};
                            `}
                        >
                            <GoogleAuthButton onAuth={() => router.push('/')} />
                            <MicrosoftAuthButton />
                        </Box>
                        <Box
                            css={css`
                                font-size: ${fontSizes.f16};
                                font-weight: ${fontWeights.bold};
                            `}
                        >
                            {translate.rich('book-a-demo', {
                                link: DemoLink,
                            })}
                        </Box>
                    </Box>
                    <Box
                        css={css`
                            font-size: ${fontSizes.f12};
                            color: ${colors.grays.tundora};
                            max-width: 435px;
                        `}
                    >
                        {translate.rich('disclaimer-text', {
                            tos: DisclaimerLink(TERMS_OF_SERVICE_URL),
                            pp: DisclaimerLink(PRIVACY_POLICY_URL),
                            gpp: DisclaimerLink(GOOGLE_API_SERVICES_USER_DATA_POLICY_URL),
                        })}
                    </Box>
                </Box>
                <Box
                    css={css`
                        flex-basis: 63%;
                        max-width: 920px;
                        height: 100%;
                        max-height: 836px;
                        background-color: ${colors.greens.narvik30};
                        border: 1px solid ${colors.greens.hintOfGreen};
                        border-radius: ${spacing.space8px};
                    `}
                >
                    <Box
                        css={css`
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            padding-left: 113px;
                        `}
                    >
                        <Box
                            css={css`
                                margin-top: 60px;
                                font-family: ${fontFamilies.poppins};
                                font-size: ${fontSizes.f24};
                                font-weight: ${fontWeights.bold};

                                display: flex;
                                flex-direction: column;
                                gap: ${spacing.space32px};
                                overflow: clip;
                            `}
                        >
                            <Box
                                css={css`
                                    max-width: 549px;
                                `}
                            >
                                {translate('customer-testimonial')}
                            </Box>
                            <Box
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    gap: ${spacing.space4px};
                                    font-family: ${fontFamilies.inter};
                                `}
                            >
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f16};
                                    `}
                                >
                                    {translate('customer-testimonial-author')}
                                </Box>
                                <Box
                                    css={css`
                                        font-size: ${fontSizes.f14};
                                        font-weight: ${fontWeights.normal};
                                    `}
                                >
                                    {translate('customer-testimonial-title')}
                                </Box>
                            </Box>
                            <Box
                                css={css`
                                    width: 921px;
                                    height: 585px;
                                    border-radius: ${spacing.space16px};
                                    border-top-right-radius: 0;
                                    border-bottom-right-radius: 0;
                                    border: 4px solid ${colors.grays.tundora};
                                    border-right: 0;
                                    background-image: url('/login_img.png');
                                    background-size: auto 102%;
                                    background-repeat: no-repeat;
                                `}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
export async function getStaticProps({ locale }: { locale: Locales }) {
    return {
        props: {
            messages: pick((await import(`translations/${locale}.json`)).default, namespaces),
        },
    };
}

export default Login;
